.navigation {
  padding: 30px 0 0;
}



.navigation .navigation-item {
  padding: 50px 0;
  text-align: center;
  background: #1075bd;
  border-radius: 3px;
  margin-bottom: 30px;
}

.navigation .navigation-item a:hover {
  text-decoration: none;
}

.navigation .navigation-item span {
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
}

@media (max-width: 767px) {
  #home-link-box .navigation-item {
    padding: 10px;
    min-height: 160px;
  }

    .navigation .navigation-item span {
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #home-link-box .navigation-item {
        padding: 10px;
        min-height: 160px;
    }
    .navigation .navigation-item span {
        font-size: 16px;
    }
}