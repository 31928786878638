.pagination-custom-container {
  .pagination {
    border-radius: unset;

    .page-item {
      .page-link {
        border: 0;
        border-radius: 4px;
        color: #444444;
        padding: 6px 12px;
        margin: 0 3px;
        background-color: transparent;

        &:hover {
          background-color: #e9ecef;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &.active {
        .page-link {
          background-color: #1075BD;
          color: #ffffff;
        }
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}