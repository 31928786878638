.guide-use-map-container {
    overflow: hidden;

    #accordion-guide-use-map {
        padding-top: 15px;

        .list-group {
            padding: 0 15px;

            .list-group-item {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #1075BD;
                pointer-events: none;
            }

            div[class$='collapsed'] {
                cursor: pointer;
                pointer-events: unset;
                color: #333333;
            }

            .active {
                background-color: #1075BD;
                color: white;
                outline: none;
            }
        }

        .content-collapse {
            margin-left: -10px;
            padding-right: 10px;
            padding-bottom: 16px;

            .collapse {
                height: 92%;
                overflow: auto;
            }
        }
    }
}

.news .new-details .new-desc {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px;
}

.news .new-details .new-content {
    font-size: 18px;
    line-height: 21px;
}

.news .new-details .new-content img {
    display: block;
    margin: 0 auto 10px;
    max-width: 100%;
}

.news .new-details .new-content > img {
    display: block;
    margin: 0 auto 10px;
    max-width: 100%;
}